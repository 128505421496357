/* Generics */

html, body {
  /* background-color: antiquewhite; */
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  margin: .5em;
}

p {
  margin: 0;
}

 img {
  max-width: 100%;
  height: auto;
}

/* Element-Specific Styling */
/* Page */
.page {
  margin: 0 0 1em 0;
}
/* Landing Screen */
.landing {
  background-image: url(/assets/landing-background.jpg);
  background-attachment: fixed;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  height: 100vh;
  position: relative;
}

/* Header */

.header {
  background: transparent;
  /* display: flex;
  flex-direction: column; */
  /* box-shadow: none; */
  padding: 15px 0;
}

.header--navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1em;
}

.header--navbar-logo {
  width: 8%;
}

.header--navbar-navigation {
  width: 92%;
  display: flex;
  justify-content: flex-end;
}

.header--navitems ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
}

.header--navitems li {
  list-style-type: none;
}

.header--navitems a {
  color: rgb(0,0,0);
  font-weight: 500;
  padding: 0 25px 0 25px;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .header--navbar {
    flex-direction: column;
    justify-content: center;
  }
  .header--navbar-logo {
    width: 25%;
  }
  .header--navbar-navigation {
    display: none;
  }

}

/* Hero */
.hero--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero--typist-wrapper-outer {
  height: 100%;
  margin-top: 10%;
}

.hero--typist-wrapper-inner {
  padding: 15px 30px;
  font-size: 2em;
  background-color: rgba(255, 255, 255, .4);
}

/* Full-Screen Landing Footer */

.hero--footer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 5px;
}

.footer--navitems {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer--navitem {
  list-style-type: none;
  margin: 0.5em 0; 
}

.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

.about--content {
  display: flex;
  width: 100%;
}

.about--content-tile {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 5%;
}

.about--content-tile img {
  width: 50%;
  float: right;
  object-fit: fill;
}

@media only screen and (max-width: 768px) {
  .about--content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about--content-tile {
    width: 80%;
    align-items: center;
  }

  .about--content-tile img {
    width: 100%;
    float: none;
    text-align: center;

  }
}

/* Education */
.education {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.education--content {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 25px;
}

.education--content-tile {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 5%;
}

.education--education-list {
  display: flex;
  justify-content: flex-end
}

.education--education-list>ul {
  padding: 0;
}


@media only screen and (max-width: 768px) {
  .education--content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .education--content-tile {
    width: 80%;
    align-items: center;
  }
}

.work {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work--content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact--content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}